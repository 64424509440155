import i18n from "../i18n";

const apiUrl = process.env.REACT_APP_API === 'production' ?
    "https://api.cora.apizr.io/v1/wine" :
    "https://api.cora-preprod.apizr.io/v1/wine/events";

const apiKey = process.env.REACT_APP_API === 'production' ?
    '47000366-a516-47dc-83d8-4cebe593907a' :
    '2685bcd8-9a45-47c2-834f-3c101145a23c';


function call(method, url, body) {
    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            'Accept-Language': i18n.language,
        },
        body: body,
        credentials: 'include'
    }
    let input = apiUrl + url
    return fetch(input, options);
}

export default call;