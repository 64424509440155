import React from 'react';
import './ChatBubble.css' ;

const ChatBubble = ({k, photo, who, own, what}) => {
    return (
        <div className="chat-bubble" key={"chat" + k}>
            <span className={"toto"}>
                <div className={"d-inline-flex fixed-50"}><img src={photo} alt={who} className={"fixed-50"}/></div>
                <div className={"d-inline-flex padded-left padded-bottom"}><b className={""}>{who}</b>, <i
                    className={"promo-red-bold"}>{own}</i>
                </div>
            </span>
            <div className={"d-inline-flex"}>
                <div className={"fixed-50"}></div>
                <div className={"padded-left"}>{what}</div>
            </div>
        </div>)
};

export default ChatBubble;
