import getAllDegustations from "./get_degustations";
import call from "./url";

const getRegistration = async (id, setter) => {
    return await call("GET", '/registrations/' + id, null)
        .then(
            (response) => {
                if (response.status === 200) {
                    response.json().then(
                        (jsonData) => {
                            let eventId = jsonData.eventId
                            call("GET", '/hosts?onlyFuture=true&eventId=' + eventId, null).then(
                                (reply2) => {
                                    if (reply2.status === 200) {
                                        reply2.json().then(jData => {
                                            jData[0].registration = jsonData
                                            setter(jData);
                                        });
                                    } else {
                                        getAllDegustations(setter)
                                    }
                                })
                        }
                    )
                } else {
                    getAllDegustations(setter);
                }
            });

};

export default getRegistration;

