import call from "./url";
import {t} from "i18next";

const updateRegistration = async (id, registrationData, setError) => {
    return await
        call('PUT', "/registrations/" + id, JSON.stringify(registrationData))
            .then(
                (response) => {
                    if (response.status === 200) {
                        setError("OK")
                    } else {
                        console.log("error :( status " + response.status + ")")
                        setError(t('error'))
                    }
                });

};

export default updateRegistration;

