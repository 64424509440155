import React from 'react';
import './ChangeLanguage.css';
import i18n from "../i18n";

const ChangeLanguage = ({changeLanguage, showCurrent}) => {
    const change = (lng) => {
        changeLanguage(lng);
    }

    function nextLanguage() {
        if (i18n.language === 'FR') {
            return 'NL'
        }
        return 'FR'
    }

    return (
        <div className={"row"}>
            <div className={"col-md-6 col-xl-6 col-sm-12 col-xs-12"}>
                <button type="submit"
                        className={"change-language-button button-arrondi selected-language"}
                        onClick={() => change(i18n.language)}>
                    {i18n.language}
                </button>
            </div>
            <div className={"col-md-6 col-xl-6 col-sm-12 col-xs-12"}>
                <button type="submit"
                        className={"change-language-button button-arrondi"}
                        onClick={() => change(nextLanguage())}>
                    {nextLanguage()}
                </button>
            </div>
        </div>)

}

export default ChangeLanguage;
