import React from 'react';
import './Header.css';
import Logo from '../Icone/coraWine_line.png';
import ChangeLanguage from './ChangeLanguage';
import {t} from "i18next";

const Header = ({changeLanguage}) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-auto align-self-center fixed-100 padded">
                    <img className={"fixed-100"} src={Logo} alt="Logo"/>
                </div>

                <div className="col">
                    <div className={"row"}>
                        <div className="col-6 align-self-center text-center ">
                            <a href="#event-list" className="text-black gothic hover no-link">
                                {t('new-reservation')}
                            </a>
                        </div>
                        <div className="col-4 align-self-center text-center century">
                            <a href="#manage" className="text-black gothic hover no-link">
                                {t('manage-reservation')}
                            </a>
                        </div>
                        <div className="col-2 text-lg-end align-right ">
                            <ChangeLanguage showCurrent={true} changeLanguage={changeLanguage}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
