import call from "./url";
import {t} from "i18next";

const register = async (id, registrationData, setError, setRegistration) => {
    return await call('POST', '/events/' + id + "/registrations", JSON.stringify(registrationData))
        .then((response) => {
            if (response.status === 201) {
                response.json().then(jData => {
                    setError("OK");
                    let loc = jData.id
                    registrationData.id = loc
                    registrationData.eventId = id
                    setRegistration(registrationData);
                    return loc
                });
            } else if (response.status === 204) {
                setError("ALREADY")
            } else {
                console.log("error :( status " + response.status + ")")
                setError(t('error'))
            }
        });

};

export default register;

