import React from 'react';
import {t} from "i18next";
import parse from "html-react-parser";

const PromoCode = () => {
    return (
        <div className="container-md sized  ">
            <div className={"infos-content"}>
                <div className="infos-section">
                    <h2>{parse(t('promo code'))}</h2>
                    <p className={"font-gothic"}> {parse(t('promo text'))}</p>
                    <p/>
                    <div  className={"font-gothic"}>{parse(t('promo text 2'))}</div>
                </div>
            </div>
        </div>)
};

export default PromoCode;
