import React from 'react';
import PromoAndText from './PromoAndText';
import IReserveButton from './IReserveButton';

const Promo = () => {
    return (
        <div className="promo-container">
            <PromoAndText/>
            <div className="spaced">
            </div>
            <IReserveButton/>
            <div className="spaced">
            </div>
        </div>
    );
}

export default Promo;
