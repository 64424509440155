import React from 'react';

const Counter = ({participants, setParticipants}) => {

    const increment = (e) => {
        e.preventDefault();
        if (participants < 4) {
            setParticipants(participants + 1);
        }
        return false;
    }
    const decrement = (e) => {
        e.preventDefault();
        if (participants > 1) {
            setParticipants(participants - 1);
        }
        return false;
    }
    return (
        <div className={"row counter"}>
            <div className={"col-3"}>
                <button onClick={decrement} onSubmit={() => false}
                        className={"button-counter decrement"}>-
                </button>
            </div>
            <div className={"col-3"}>
                <input type={"number"} min={1} max={4} value={participants} disabled={true}
                       className={"participant-counter "}/>
            </div>
            <div className={"col-3"}>
                <button onClick={increment} onSubmit={() => false}
                        className={"button-counter button-red increment"}>+
                </button>
            </div>
        </div>
    )

}

export default Counter;
