import React, {useState} from 'react';
import {t} from "i18next";
import Period from "./Period";
import register from "../backend/register";
import cancel from "../backend/cancel";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Counter from "./Counter";
import * as rdd from "react-device-detect";
import i18n from "../i18n";
import updateRegistration from "../backend/update_registration";

const Registration = ({host, setCancelled, reloadHost}) => {

    const [reg, setRegistration] = useState(host ? host.registration : {});
    const [nom, setNom] = useState(reg ? reg.lastName : "");
    const [prenom, setPrenom] = useState(reg ? reg.firstName : "");
    const [email, setEmail] = useState(reg ? reg.email : "");
    const [participants, setParticipants] = useState(reg ? reg.numberOfGuest : 1);
    const [evt, setEvent] = useState(reg ? reg.eventId : host.events[0].id);

    const [error, setError] = useState('');
    const [success, showSuccess] = useState(false);
    const [already, showAlready] = useState(false);
    const [validated, setValidated] = useState(false);

    const handlePeriodChoice = (id) => {
        setEvent(id);
    }

    const hasError = () => {
        return error !== '';
    }


    const handleCancel = async (event) => {
        showSuccess(false);
        showAlready(false);
        setError('');
        event.stopPropagation();
        event.preventDefault();
        try {
            await cancel(reg.id, setError, setCancelled);
            await reloadHost()
            setCancelled(true);
        } catch (e) {
            console.log(e)
        }
    }
    const handleSubmit = async (event) => {
        showSuccess(false);
        showAlready(false);
        setCancelled(false);
        setError('');
        const form = event.currentTarget;
        event.stopPropagation();
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
            event.preventDefault();
        } else {
            let registration = {
                lastName: nom,
                firstName: prenom,
                email: email,
                numberOfGuest: participants,
                period: evt,
                language: i18n.language,
                eventId: evt,
            };
            if (reg && reg.id) {
                await updateRegistration(reg.id, registration, updateError).then()
            } else {
                await register(evt, registration, updateError, updRegistration)
                    .then()
            }
        }
    };
    const updRegistration = (registration) => {
        setRegistration(registration)
        reloadHost(registration.id)
    }
    const updateError = (value) => {
        showAlready(false)
        showSuccess(false)
        setCancelled(false)
        if (value.startsWith("OK")) {
            showSuccess(true)
        } else if (value === "ALREADY") {
            showAlready(true)
        } else {
            setError(value)
        }
    }

    return (
        <Form id={"registration-form"} noValidate
              className={"mt-3"}
              validated={validated}
              onSubmit={handleSubmit} key={"reg" + evt.id}>
            <div className={"row"}>
                <Form.Group className={"form-group"} as={Col} md="6" controlId="registrationCustomerNameValidation">
                    <Form.Control className={"form-control input-arrondi "} type="text"
                                  value={nom}
                                  onChange={(e) => setNom(e.target.value)}
                                  required
                                  placeholder={t('nom')}/>

                    <Form.Control.Feedback type="invalid">{t('invalid-lastname')}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="registrationCustomerFistNameValidation">

                    {rdd.isMobile && <div className={"padded"}/>}
                    <Form.Control className={"form-control input-arrondi "} type="text"
                                  value={prenom}
                                  onChange={(e) => setPrenom(e.target.value)}
                                  required
                                  placeholder={t('prenom')}/>

                    <Form.Control.Feedback type="invalid">{t('invalid-firstname')}</Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className={"row"}>
                <Form.Group as={Col} md="12" controlId="registrationCustomerEmailValidation" className={"mt-2"}>
                    <Form.Control className={"form-control text-lowercase input-arrondi "} type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                  placeholder={t('email')}
                    />
                    <Form.Control.Feedback type="invalid">{t('invalid-email')}</Form.Control.Feedback>
                </Form.Group>
            </div>
            <Partic participants={participants}
                    setParticipants={setParticipants}
                    host={host}
                    evt={evt}
                    reg={reg}
                    handlePeriodChoice={handlePeriodChoice}/>
            <div className={"row"}>
                <div className={"col-12"}>
                    {reg && reg.cancelled === false &&
                        <button className={"button button-arrondi text-bold no-back"}
                                onClick={handleCancel}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-trash" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                                <path
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                            </svg>
                            {t('cancel')}
                        </button>}
                </div>
            </div>
            {
                hasError() && <div>{error}</div>
            }
            {
                success &&
                <div className={"container success-registration"}>
                    {t('success-registration')}
                </div>
            }
            {
                already &&
                <div className={"container already-registration"}>
                    {t('already-registration')}
                </div>
            }
        </Form>)
};

const Partic = ({participants, reg, setParticipants, host, evt, handlePeriodChoice}) => {
    return (
        <div className={"row mt-3"}>
            <div className={"col-md-2 col-sm-3 text-left text-bold"}>
                {t('participants')}
                <Counter participants={participants} setParticipants={setParticipants}></Counter>
            </div>
            <div className={"col-md-7 col-sm-10 text-left text-bold"}>
                {t('sceance')}
                <span className={"periods smaller-text "}>
                        {
                            host.events.map(
                                (e) => <Period
                                    selected={evt}
                                    event={e}
                                    key={e.id}
                                    s={host.events.length}
                                    selectPeriod={handlePeriodChoice}/>)

                        }
                    </span>
            </div>
            <div className={"col-3 text-left text-bold"}>
                <button type="submit"
                        className={"button-validate button-red smaller-text big-but mt-4"}>
                    {reg && reg.id !== "" ?
                        t('update-inscr') :
                        t('validate')}
                </button>
            </div>
        </div>
    )
}

export default Registration;
