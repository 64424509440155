import React from 'react';
import './PromoAndText.css';
import parse from "html-react-parser";
import i18n, {t} from "i18next";
import logodegustation from '../Icone/logo_degustations_fr.svg'
import logodegustationNl from '../Icone/logo_degustations_nl.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const PromoAndText = () => {

    var image = logodegustation;
    if (i18n.language === "NL" || i18n.language === "nl") {
        image = logodegustationNl;
    }
    return (
        <div className="promo-container">
            <div className="promo-text">
                <div className="logo-degustation">
                    <img className={"degustation-logo"} alt={"logo"} src={image}/>
                </div>
                <div className="promo-line1"> {parse(t('inscription'))}</div>
                <div className={"promo-participation"}>{parse(t('participate'))}
                </div>
                <div className={"promo-28-1"}>{parse(t('promo-28-ans'))}
                </div>
                <div className="promo-28-3">
                    <ul>
                        <li style={{display: 'flex', alignItems: 'left'}}>
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: '8px', color: "green"}}/>
                            {t('70vignerons')}
                        </li>
                        <li style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: '8px', color: "green"}}/>
                            {t('300vins')}
                        </li>
                        <li style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: '8px', color: "green"}}/>
                            {t('free')}
                        </li>
                        <li style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: '8px', color: "green"}}/>
                            {t('convivial')}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default PromoAndText;
