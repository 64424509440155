import React, {useEffect, useState} from 'react';
import './App.css';
import Promo from './Promo/Promo';
import Infos from './Promo/Infos';
import EventList from './components/EventsList';
import getAllDegustations from "./backend/get_degustations";
import RegistrationManage from "./components/RegistrationManage";
import MentionLegale from "./components/MentionLegale";
import PromoCode from "./components/PromoCode";
import PeopleSaid from "./components/PeopleSaid";
import Help from "./components/Help";
import getRegistration from "./backend/get_registration";
import i18n from "./i18n";
import Header from "./components/Header";
import {withTranslation} from 'react-i18next';

const App = ({t}) => {

    const [hosts, setHosts] = useState([]);


    const reloadHost = (id) => {
        const fetchData = async () => {
            if (id) {
                await getRegistration(id, setHosts)
            } else {
                await getAllDegustations(setHosts);
            }
        };
        fetchData()
            .catch(error => console.log(error));
        const element = document.getElementById('event-list');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        let url = window.location.href;
        if (url) {
            const t = url.lastIndexOf('/');
            const l = url.substring(t + 1, t + 3);
            if (l === 'NL' || l === 'nl') i18n.changeLanguage('NL');
            else i18n.changeLanguage('FR');
        }
        // Function to call based on whether id is filled or empty
        const fetchData = async () => {
            if (id) {
                await getRegistration(id, setHosts)
            } else {
                await getAllDegustations(setHosts);
            }
        };

        fetchData()
            .catch(error => console.log(error));
        if (url.endsWith("#event-list")) {
            const element = document.getElementById('event-list');
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, []);

    return (
        <div>
            <div className={"app"}>
                <Header changeLanguage={i18n.changeLanguage} className={"sized"}/>
                <hr className={"separator"}/>
                <Promo/>
            </div>
            <div className={"infos-container"}>
                <div className={"app"}>
                    <Infos/>
                </div>
            </div>
            <div className={"promo-container"}>
                <div className={"app"}>
                    <EventList hosts={hosts} reloadHost={reloadHost}/>
                    <RegistrationManage/>
                </div>
            </div>
            <div className={"infos-container"}>
                <div className={"app"}>
                    <PromoCode/>
                </div>
            </div>

            <div className={"app"}>
                <PeopleSaid/>
                <hr/>
                <div className={"container-md"}>
                    <Help/>
                </div>
            </div>
            <div className={"app"}>
                <MentionLegale/>
            </div>
        </div>
    );
};

export default withTranslation()(App);
