import React, {useState} from 'react';
import Event from './Events';
import {t} from "i18next";

import './EventsList.css';
import parse from "html-react-parser";

const EventList = ({hosts, onReserveClick, reloadHost}) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [cancelled, setCancelled] = useState(false);

    function allEventClosed(events) {
        for (let event of events) {
            if (event.isOpen === true) {
                return false;
            }
        }
        return true;
    }

    const handleReserveClick = (evt) => {
        if (allEventClosed(evt.events)) {
            return;
        }
        if (selectedEvent === evt.id) {
            setSelectedEvent("")
        } else {
            setSelectedEvent(evt.id);
        }
    };

    if (hosts.length === 0) {
        return (
            <div id="event-list" className="eventslist">
                <div className="event-list">
                    {t('no data found')}
                    <hr/>
                </div>
            </div>);
    }
    return (
        <div id="event-list" className="eventslist">
            <h2 className={"red-text"}>{t('reservation')}</h2>
            <p className="choose-session-text font-gothic">{parse(t('choose degustation'))}</p>
            {cancelled &&
                <div className={" py-2"}>
                    <div className={"container success-registration"}>
                        {t('success-deleted')}
                    </div>
                </div>}
            {hosts.map((host) => (
                <Event
                    key={host.id}
                    host={host}
                    onReserveClick={onReserveClick}
                    handleReserveClick={() => handleReserveClick(host)}
                    isSelected={selectedEvent === host.id || (host.registration !== undefined)}
                    reloadHost={reloadHost}
                    setCancelled={setCancelled}
                />
            ))}
        </div>
    );
};

export default EventList;
