import React from "react";
import {t} from "i18next";


const Period = ({selected, event, s, selectPeriod}) => {
    const start = new Date(event.startTime);
    const end = new Date(event.endTime);

    function zeroPad(minutes) {
        if (minutes === 0)
            return "00";
        return minutes;
    }

    function closedRegistrationClass(event, selected) {

        if (selected === event.id) {
            if (!event.isOpen) {
                return "button-disabled"
            }
            return "button-red"
        }
        return ""
    }

    return (
        <div key={event.id}
             className={"event-period button-bordered period-item smaller-text " + closedRegistrationClass(event, selected)}
             onClick={() => selectPeriod(event.id)}>
            {start.getHours() + t('heure') + zeroPad(start.getMinutes()) + " - " + end.getHours() + t('heure') + zeroPad(end.getMinutes())}
        </div>);
}
export default Period;