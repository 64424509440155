import React from 'react';
import {t} from "i18next";
import parse from "html-react-parser";
import autoworld from "../Icone/icon_autoworld.png";
import chatelineau from "../Icone/icon_chatelineau.png";
import hornu from "../Icone/icon_hornu.png";
import lalouviere from "../Icone/icon_lalouviere.png";
import messancy from "../Icone/icon_messancy.png";
import rocourt from "../Icone/icon_rocourt.png";
import thelab from "../Icone/icon_thelab.png";
import Registration from "./Registration";

import i18n from "../i18n";

const Event = ({host, handleReserveClick, isSelected, reloadHost, setCancelled}) => {

    const logo = new Map()
    logo.set(1, autoworld);
    logo.set(2, chatelineau);
    logo.set(3, hornu);
    logo.set(4, lalouviere);
    logo.set(5, messancy);
    logo.set(6, rocourt);
    logo.set(7, thelab);
    const start = new Date(host.events[0].startTime);
    const day = t('day' + start.getDay());
    const month = t('month' + (start.getMonth() + 1));

    function zeroPad(minutes) {
        if (minutes === 0)
            return "00";
        return minutes;
    }

    function closedRegistrationClass(events) {
        let b = allEventClosed(events);
        if (b === true) {
            return "disabled-button"
        }
        return "button-red"
    }

    //TODO Dirty fix
    function mapLoc(location) {
        if (location.indexOf("Bruxelles") === -1) {
            return location
        }
        if (i18n.language === 'NL') {
            return location.replaceAll("Bruxelles", "Brussel")
        }
        return location;
    }

    function allEventClosed(events) {
        for (let event of events) {
            if (event.isOpen === true) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="event">
            <div className={"row"} onClick={handleReserveClick}>
                <div className="col-auto align-self-center fixed-100">
                    <div className={"event-date day"}>{day}</div>
                    <div className={"event-date date"}>{start.getDate()}</div>
                    <div className={"event-date month"}>{month}</div>
                </div>
                <div className="col-auto align-self-center fixed-100 small-hidden">
                    <img src={logo.get(host.logo)} alt="Event Logo" className={"img"}/>
                </div>
                <div className="col-3 align-self-center event-location">{parse(mapLoc(host.location))}</div>
                <div className="col-3 align-self-center">
                    {
                        host.events.map(
                            (event) => {
                                const start = new Date(event.startTime);
                                const end = new Date(event.endTime);
                                const div = "<div key=\"" + event.id + "\">"
                                return parse(div + start.getHours() + t('heure') + zeroPad(start.getMinutes()) + " - " + end.getHours() + t('heure') + zeroPad(end.getMinutes()) + "</div>");
                            })

                    }
                </div>
                <div className="col-2 align-self-center">
                    <input type={"button"}
                           onClick={handleReserveClick}
                           disabled={allEventClosed(host.events)}
                           value={t('reserver')}
                           className={"small-fit-button " + closedRegistrationClass(host.events)}/>
                </div>
            </div>

            {isSelected && (
                <Registration
                    eventId={host.id}
                    host={host}
                    reloadHost={reloadHost}
                    setCancelled={setCancelled}
                />)}

            <hr/>
        </div>

    )
};

export default Event;
