import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translationFr from './locale/translations.fr.json';
import translationNl from './locale/translations.nl.json';

// the translations
const resources = {
    FR: {translation: translationFr},
    NL: {translation: translationNl}
};
i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "FR",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;