import call from "./url";
import {t} from 'i18next';

const cancel = async (id, setError, showDeleted) => {
    return await
        call('DELETE', '/registrations/' + id, null)
            .then(
                (response) => {
                    if (response.status === 200) {
                        showDeleted(true);
                    } else {
                        console.log("error :( status " + response.status + ")")
                        setError(t("error"))
                    }
                });

};

export default cancel;

