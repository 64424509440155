import React from 'react';

const MentionLegale = () => {
    return (<div className={"mention-legale"}>
        cora s.a. Rue de Capilône 10, B-6220 Heppignies, TVA-BE-0402.537.726 - RPM Charleroi
        <br/>
        &copy;2024 | cora Wine | www.corawine.be
    </div>)
}

export default MentionLegale