import React, {useState} from 'react';
import {t} from "i18next";
import parse from "html-react-parser";
import sendResendEmail from "../backend/send_email";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as rdd from "react-device-detect";

const RegistrationManage = () => {
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);

    const resendEmail = async (event) => {
        const form = event.currentTarget;
        event.stopPropagation();
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
            event.preventDefault();
        } else {
            await sendResendEmail(email)
                .then(r => "ok" + r)
                .catch(e => e.toString());
        }

    };
    return (
        <Form id={"registration-manage"}
              noValidate validated={validated} className={"mt-md-4" + rdd.isMobile ? "padded" : ""}
              onSubmit={resendEmail}>
            <div id={"manage"}>
                <h2>{parse(t('manage registration'))}</h2>
                <div className={"font-gothic"}>{parse(t('you want to manage'))}</div>
                <div className={"font-gothic"}>{parse(t('manage explain'))}</div>
                <div className={"font-gothic spaced"}/>
                {!rdd.isMobile &&
                    <Row>
                        <Form.Group className={"padded"} as={Col} md="8" controlId="emailRegistrationManageValidation">
                            <Form.Control className={"form-control text-lowercase input-arrondi"} type="email"
                                          value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          required
                                          placeholder={t('email')}
                            />
                            <Form.Control.Feedback type="invalid">{t('invalid-email')}</Form.Control.Feedback>
                        </Form.Group>
                        <Col md={"4"} className={"padded"}>
                            <button type="submit"
                                    className={"button-arrondi button-red mb-2"}>
                                {t('validate-v')}
                            </button>
                        </Col>
                    </Row>}
                {rdd.isMobile && <div>
                    <Row className={"text-center"}>
                    <Form.Group className={""} as={Col} md="12" controlId="emailRegistrationManageValidation">
                        <Form.Control className={"form-control "} type="text"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      required
                                      placeholder={t('email')}
                        />
                        <Form.Control.Feedback type="invalid">{t('invalid-email')}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                    <Row>
                        <Col md={"12"} className={"padded text-center"}>
                            <button type="submit"
                                    className={" align-self-center button-arrondi button-red mb-2"}>
                                {t('validate-v')}
                            </button>
                        </Col>
                    </Row></div>}
            </div>
        </Form>);
};

export default RegistrationManage;
