import React from 'react';
import {t} from "i18next";

const IReserveButton = () => {
    return (
        <div className={""}>
            <a href="#event-list" className={"button button-red big-button button-arrondi"}>
                {t('i-reserve')}
            </a></div>

    );
}

export default IReserveButton;
