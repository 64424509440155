import React from 'react';
import mela from '../Icone/user_icon.svg';
import marc from '../Icone/marc.svg';
import eric from '../Icone/eric.svg';

import {t} from "i18next";
import ChatBubble from "./ChatBubble";
import parse from "html-react-parser";

const RegistrationManage = () => {

    const said = [
        {
            key: "2",
            who: "Marc Roisin",
            own: "Vinogusto",
            photo: marc,
            what: "mroisin"
        },
        {
            key: "1",
            who: "Mélanie Coppens",
            own: "cliente",
            photo: mela,
            what: "coppens"
        },
        {
            key: "3",
            who: "Eric Boschman",
            own: "Sommelier",
            photo: eric,
            what: "boshman"
        },
    ]

    const isOdd = said.length % 2 !== 0;

    return (
        <div className={"container justify-content-center"}>
            <div className={"row"}>
                {
                    said.map((s, index) => {
                        const isLast = index === said.length - 1;

                        if (isLast && isOdd) {
                            return (
                                <div className={"align-content-center col-md-12 col-xl-12 col-sm-12 col-xs-12"} key={"regM"+index}>
                                    <div className={"md-centered"}><ChatBubble
                                        k={index}
                                        who={s.who}
                                        what={parse(t(s.what))}
                                        own={s.own}
                                        photo={s.photo}

                                    />
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className={"col-md-6 col-xl-6 col-sm-12 col-xs-12"} key={"regM"+index}>
                                    <ChatBubble
                                        k={index}
                                        who={s.who}
                                        what={parse(t(s.what))}
                                        own={s.own}
                                        photo={s.photo}

                                    />
                                </div>
                            );
                        }
                    })

                }
            </div>
        </div>);
};

export default RegistrationManage;
