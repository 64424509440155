import call from "./url";

const getAllDegustations = async (setter) => {
    let events = [];
    return await call('GET', "/hosts?onlyFuture=true", null)
        .then(response => response.json())
        .then(jsonData => setter(jsonData))
        .catch(error => {
            console.log(error)
            setter(events);
        })

};

export default getAllDegustations;

