import React from 'react';
import {t} from "i18next";
import facebook from "../Icone/facebook.png";
import instagram from "../Icone/instagram.png";
import linkedin from "../Icone/linkedin.png";
import youtube from "../Icone/youtube.png";
import parse from "html-react-parser";
import i18n from "../i18n";

const PromoCode = () => {

    const siteUrl = () => {
        if (i18n.language === "NL" || i18n.language === "nl") {
            return "https://www.corawine.be/nl"
        }
        return "https://www.corawine.be/fr"
    }
    return (<div className={"row"}>
            <div className={"col-md-8"}>
                <div>
                    <div className={"text-bold"}>{t('need help')}</div>
                    <li>{parse(t('contact-website'))}</li>
                    <li>{parse(t('call-us'))}</li>
                </div>
            </div>
            <div className={"col-md-1"}></div>
            <div className={"col-md-3"}>
                <div>
                    <div className={"text-bold"}>{t('stay in touch')}</div>
                    <div>
                        <a rel="noopener noreferrer" target={"_blank"} href={"https://www.facebook.com/corawinebe"}><img
                            className={"social"} src={facebook} alt={"facebook"}/></a>
                        <a rel="noopener noreferrer" target={"_blank"}
                           href={"https://www.instagram.com/corawinebe/"}><img className={"social"} src={instagram}
                                                                               alt={"instagram"}/></a>
                        <a rel="noopener noreferrer" target={"_blank"}
                           href={"https://www.linkedin.com/showcase/86299240/admin/feed/posts/"}><img
                            className={"social"} src={linkedin} alt={"linkedin"}/></a>
                        <a rel="noopener noreferrer" target={"_blank"}
                           href={"https://www.youtube.com/@coraWinebelgium"}><img className={"social"} src={youtube}
                                                                                  alt={"youtube"}/></a>
                    </div>
                    <a className={"no-l"} rel="noopener noreferrer" target={"_blank"}
                       href={siteUrl()}>www.corawine.be</a>
                </div>
            </div>
        </div>
    )

};

export default PromoCode;
