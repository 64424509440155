import React from 'react';
import './Infos.css';
import {t} from "i18next";
import parse from "html-react-parser";

const Infos = () => {
    return (
        <div className="container-md sized  ">
            <div className={"infos-content"}>
                <div className="infos-section">
                    <h2>{parse(t('how-to'))}</h2>
                    <ul className={"font-gothic"}>
                        <li>{t('choose')}</li>
                        <li>{t('choose-part')}</li>
                        <li>{t('set-email')}</li>
                        <li>{parse(t('get-email'))}
                        </li>
                    </ul>
                </div>
                <div className="infos-section">
                    <h2>{parse(t('how-much'))}</h2>
                    <ul className={"font-gothic"}>
                        <li><span className="bullet"></span>{parse(t('price'))}</li>
                        <li><span className="bullet"></span>{parse(t('payement'))}</li>
                        <li><span className="bullet"></span>{parse(t('gift'))}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Infos;
